import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ChangeDetectorRef,
} from "@angular/core";
import { AssetService } from "../../../services/asset.service";
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { ConfigService } from "../../../services/config.service";
import { BeaconGatewayService } from "../../../services/beacon-gateway.service";
import { RfidGatewayService } from "../../../services/rfid-gateway.service";
import { EventManagementService } from "../../../services/eventManagement.service";
import { FormService } from "../../../services/form.service";
declare var $: any;
import { clone, forEach, groupBy, map, includes, keys } from "lodash";
import { DateRangePickerService } from "../../../services/date-range-picker.service";
import { ConfirmationService } from "primeng/api";
import { LangUtilService } from "../../util/lang-util.service";
// import { MapMarkerComponent } from "../../standalone-components/map-marker/map-marker.component";
import { TrackerIcons } from "../../../state/Fleet/models/fleet.models";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { selectMarkerIcons } from "../../../state/Fleet/fleet.selector";
import { CONSTANT } from "../../../config/constant";
import { getAddressDetailsSelector, getUserMapSelector } from "../../../state/User/user.selector";
import { getAddressDetails } from "../../../state/User/user.action";
import { SplitScreenService } from "../../../services/split-screen.service";
import { getGeoFenceByAssetId, updateGeoFenceRaduis } from "../../../state/Assets/assets.action";
import { geoFencesForAssetSelector } from "../../../state/Assets/assets.selector";
import { TranslatePipe, TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-event-form",
  templateUrl: "./event-form.component.html",
  styleUrls: ["./event-form.component.scss"],
})
export class EventFormComponent implements OnChanges, OnInit {
  Object = Object;
  form: UntypedFormGroup;
  defaultFields: string[] = [];
  hiddenFields: Array<object> = [];
  arrayFields: Array<object> = [];
  isFormReadyToDisplay: boolean;
  showCircularLoader: boolean;
  isSubmitDisabled: boolean;
  datePickerVariables = {
    singleDatePicker:
      this.dateRangePickerService.singleDatePicker_Variabes.singleDatePicker,
    showDropdowns:
      this.dateRangePickerService.singleDatePicker_Variabes.showDropdowns,
    autoApply: this.dateRangePickerService.singleDatePicker_Variabes.autoApply,
    locale: this.dateRangePickerService.singleDatePicker_Variabes.locale,
    maxDate: this.dateRangePickerService.singleDatePicker_Variabes.getMaxDate(),
    minDate: this.dateRangePickerService.singleDatePicker_Variabes.getMinDate(),
  };
  @Input() assetTypeToSend:string
  @Input() isKioskAddMoreDetailsForm: boolean;
  @Input() isEditForm: boolean;
  @Input() isCloneBtnReq: boolean;
  @Input() showUpdateBtn: boolean;
  @Input() isAssetForm: boolean;
  @Input() showDeRegisterBtn: boolean;
  @Input() eventToEdit: any;
  @Input() formConfigData = [];
  @Input() resetFormData: number;
  @Input() isEditOptionClicked: boolean;
  @Input() isInputDisabled: boolean;
  @Input() selectedAccountId:string
  @Output() assetRegistrationErrorMsg: EventEmitter<string> = new EventEmitter();
  @Output() formSubmitHandler: EventEmitter<any> = new EventEmitter();
  @Output() closeModalEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() deregisterAsset: EventEmitter<any> = new EventEmitter();


  date = {
    startDate: null,
    endDate: null,
  };


  doubleClickOnMap
  mapVisible: boolean = false;
  mapDetails$=this.store.pipe(select(getUserMapSelector));
  trackIcons$: Observable<TrackerIcons>;
  moduleName:string=CONSTANT.DMS_MODULES.LMD.MODULE;

  latitude:number | undefined
  longitude:number | undefined

  geoFencesForAsset$=this.store.pipe(select(geoFencesForAssetSelector));

  updateGeoFenceRadiusPayload
  geoFenceRadius:number
  assetIdOfGeoFence:string
  isUnique:boolean
  countedObj:ICountedObjDisableChecker
  focusedInputElement: HTMLInputElement;
  valueExistMessage:IValueExistChecker
  debouncedHandleInputChange;
  cloneForFormValuesToedit:any
  regexValidationObj: ICountedObjDisableChecker = {};
  parentStateForPrefillValues = {}

  separatorExp: RegExp = /,| /;
  constructor(
    public assetService: AssetService,
    public configService: ConfigService,
    public beaconGatewayService: BeaconGatewayService,
    public rfidGatewayService: RfidGatewayService,
    private fb: UntypedFormBuilder,
    private dateRangePickerService: DateRangePickerService,
    private eventManagementService: EventManagementService,
    public formService: FormService,
    private lang: LangUtilService,
    private confirmationService: ConfirmationService,
    // private mapMarkerComponent: MapMarkerComponent,
    private store:Store,
    private splitScreenService:SplitScreenService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) {
    this.isFormReadyToDisplay = false;
    this.showCircularLoader = false;
    this.showDeRegisterBtn = false;
    this.isSubmitDisabled = false;
    this.isCloneBtnReq = false;
    this.showUpdateBtn = true;
    // this.doubleClickOnMap = this.mapMarkerComponent?.doubleClickOnMap;
  }


  handleInputChange(event,isUnique,field){
    if(isUnique && event.target.value != null){
      if (!this.debouncedHandleInputChange) {
        this.debouncedHandleInputChange = this.hanldeInputDebounce(() => {
          this.handleIsUniqueAPi(event?.target?.value, field);
          this.debouncedHandleInputChange = null;
        }, 700);
      }
      this.debouncedHandleInputChange(); 
    }
  }


  handleIsUniqueAPi(event, field) {
    if (this.cloneForFormValuesToedit[field] === event) {
      this.countedObj[field] = true
      this.valueExistMessage[field] = null
      return 
    }
    if (event !== '') {
      const translate = new TranslatePipe(this.translate, this.cd);
      this.assetService.getUniqueValueForEventForm(field, event, this.assetTypeToSend).subscribe((el) => {
        this.formConfigData.forEach((ele)=>{
          if(ele?.field == field){
            this.countedObj = {...this.countedObj,[ele.field]:el['isUnique'] ? el['isUnique'] : false}
            this.valueExistMessage = { ...this.valueExistMessage, [ele.field]: { isUnique: el['isUnique'], isValueExistMessage: `${translate.transform(ele?.label)} ${event} already exist!` } }
          }
        })
      })
    }
  }

  hanldeInputDebounce(func, delay) {
    let timerId;
    return function(...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  createRegexValidationObj() {
    this.formConfigData.forEach((ele) => {
      if (ele?.regexValidation?.enabled) {
        this.regexValidationObj[ele?.field] = true
      }
    })
  }

  regexValidation(event, regexValidation, field) {
    if (regexValidation?.enabled) {
      if (event) {
        const regex = new RegExp(regexValidation?.expression);
        this.regexValidationObj[field] = regex.test(event);
      } else {
        this.regexValidationObj[field] = true;
      }
    }
  }

  handleUppercaseCheck(event, isUpperCase, field) {
    if (isUpperCase && event?.target?.value != null) {
      this.form.controls[field].setValue(event?.target?.value?.toUpperCase());
    }
  }

  assignValuesOnDoubleClick(event): void {
    const formValues = this.form.value;

    formValues['lat'] = event.lat;
    formValues['lng'] = event.lng;

    this.form.controls.lat.setValue(formValues.lat);
    this.form.controls.lng.setValue(formValues.lng);

    this.updateGeoFenceRadiusPayload={lat:event.lat,lng:event.lng}

    this.latitude=event.lat;
    this.longitude=event.lng;

    this.store.dispatch(getAddressDetails({lat:this.latitude,lng:this.longitude}))
  }

  hideMapView() {
    this.mapVisible = false
    this.showGeoFenceEdit=false
  }

  showGeoFenceEdit:boolean

  showMapView() {
    this.mapVisible = this.isEditForm ? this.isEditOptionClicked : true

    this.isEditForm && this.formConfigData?.forEach(field=>{
       if(field.field==="geoFenceRadius"){
        this.showGeoFenceEdit=true
        return;
       }
    })

    this.form.value.lat &&
    this.form.value.lng &&
    this.assignValuesOnDoubleClick({lat:parseFloat(this.form.value.lat),lng:parseFloat(this.form.value.lng)})

    this.showGeoFenceEdit &&
    this.eventToEdit?._id && 
    this.assetIdOfGeoFence!==this.eventToEdit?._id && 
    this.store.dispatch(getGeoFenceByAssetId({assetId:this.eventToEdit?._id}))

    this.assetIdOfGeoFence=this.eventToEdit?._id || ''
  }
  

  updateGeoFenceRadius(radiusData){
    this.geoFenceRadius=radiusData || 0
    this.updateGeoFenceRadiusPayload={...this.updateGeoFenceRadiusPayload,geoFenceRadius:radiusData}
    this.store.dispatch(updateGeoFenceRaduis({assetId:this.eventToEdit?._id,payload:this.updateGeoFenceRadiusPayload}))
  }

  
  onChange(event,field){
    this.latitude=field==='lat' ? event.value : this.latitude
    this.longitude=field==='lng' ? event.value : this.longitude

    this.latitude &&
    this.longitude &&
    this.store.dispatch(getAddressDetails({lat:this.latitude,lng:this.longitude}))
  }

  

  submitConfigDetails() {
    this.isSubmitDisabled = true;
    this.setHiddenFieldValues(this.hiddenFields);
    this.convertStringToArray(this.arrayFields);
    this.formateDateField(this.getValuesForDefaultFields(this.form.value));
    const formValue = clone(this.form.value);
    let formValueObj = {};
    formValueObj["action"] = "save";
    formValueObj["value"] = formValue;
    if (this.isEditForm) {
      formValueObj["action"] = "update";
    }
    if (this.isCloneBtnReq && this.isEditForm) {
      formValueObj["action"] = "clone";
      setTimeout(() => {
        this.isSubmitDisabled = false;
      }, 10);
    }
    if(formValue['maxThreshold']==='0' && this.isEditForm){
      formValueObj["value"]['maxThreshold']=0
    }
    if(formValue['minThreshold']==='0' && this.isEditForm){
      formValueObj["value"]['minThreshold']=0
    }
    this.formConfigData?.map((el)=>{
      if(el?.bindTo){
        formValueObj['value'][el?.bindTo] = {...formValueObj['value'][el?.bindTo],[el?.field]:formValueObj['value'][el?.field]}
      }
    })
    this.formSubmitHandler.emit(formValueObj);
  }

  setHiddenFieldValues(hiddenValues) {
    const _this = this;
    forEach(hiddenValues, function (field: any) {
      let fieldType = field.field;
      _this.form.value[fieldType] = field.value;
    });
  }

  convertStringToArray(arrayFields) {
    const _this = this;
    forEach(arrayFields, function (field: any) {
      let fieldType = field.field;
      if (
        _this.form.value[fieldType] &&
        typeof _this.form.value[fieldType] === "string"
      ) {
        _this.form.value[fieldType] = _this.form.value[fieldType]
          ? _this.form.value[fieldType].replace(/,\s*$/, "")
          : null;
        _this.form.value[fieldType] = _this.form.value[fieldType]
          ? _this.form.value[fieldType].split(",")
          : null;
      }
    });
  }

  getValuesForDefaultFields(formValues) {
    let _this = this;
    forEach(this.defaultFields, function (field) {
      formValues[field] = _this.form.get(field).value;
    });
    return formValues;
  }

  formateDateField(formValues) {
    for (let key in formValues) {
      if (formValues[key] && typeof formValues[key] === "object") {
        if (formValues[key]["startDate"]) {
          formValues[key] = formValues[key]["startDate"];
        }
      }
    }
  }

  ngOnInit() {
    // this.formConfigData = [];

  
    this.store.select(getAddressDetailsSelector).subscribe(data => {
    
      if(this.form && data)
      {
        this.form.value['state']= data.state
        this.form?.controls?.state?.setValue(data?.state)

        this.form.value['pincode']= data.pincode
        this.form?.controls?.pincode?.setValue(data.pincode)
      }

    })
    this.trackIcons$ = this.store.select(selectMarkerIcons({module:  this.moduleName}));

  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName of Object.keys(changes)) {
      let change = changes[propName];
      if (propName === "formConfigData") {
        // this.formConfigData = [];
        if (change.currentValue !== change.previousValue) {
          if (this.showUpdateBtn == undefined) {
            this.showUpdateBtn = true;
          }
          this.valueExistMessage = null
          this.countedObj = null
          if (this.formConfigData) {
            this.isFormReadyToDisplay = false;
            this.showCircularLoader = true;
            if (this.isEditForm) {
              this.formConfigData = this.formService.formatEditAssetConfig(
                this.formConfigData,
                this.eventToEdit
              );
            }
            this.getAllDynamicData(this.formConfigData).then(
              (dynamicDataCreation) => {
                this.formConfigData = this.getDropDownLabels(dynamicDataCreation);
                if (this.isEditForm) {
                  if (this.isInputDisabled) {
                    this.createEditForm(dynamicDataCreation, true);
                  } else {
                    this.createEditForm(dynamicDataCreation, false);
                  }
                  this.checkIsCloneForm()
                  this.addingTheValuesForConfiguration()
                } else {
                  this.createAddForm(dynamicDataCreation);
                }
              }
            );

            // For Rearranging the Form Fields , So Accordian will come at end
            this.rearrangeAccordionsInPlace(this.formConfigData);
            this.createRegexValidationObj();
          }
        }
      }
      if (propName === "resetFormData") {
        if (change.currentValue !== change.previousValue) {
          this.isFormReadyToDisplay = false;
          this.showCircularLoader = true;
          this.isSubmitDisabled = false;
          this.parentStateForPrefillValues = {}
        }
      }
      if (propName === "isEditOptionClicked") {
        console.log("III")
        if (
          change.previousValue !== undefined &&
          change.currentValue !== change.previousValue
        ) {
          if (this.isEditOptionClicked === true) {
            //this.isInputDisabled = false;
            this.disabledAndEnabledFields(this.formConfigData, false);
          } else {
            //this.isInputDisabled = true;
            this.disabledAndEnabledFields(this.formConfigData, true);
          }
        }
      }
      if (propName === "eventToEdit") {
        if (
            (change.previousValue !== change.currentValue) && this.form && this.geoFenceRadius
        ) {
          const radius=change?.currentValue?.geoFenceRadius || 0
          const formValues = this.form.value;
          formValues['geoFenceRadius'] = radius;
          this.form?.controls?.geoFenceRadius?.setValue(radius);
        }
      }

      if (propName === "isCloneBtnReq") {
        if (change.currentValue !== change.previousValue) {
          this.checkIsCloneForm()
        }
      }

      if (propName === "isEditForm") {
        if (change.currentValue !== change.previousValue) {
          this.checkIsCloneForm();
        }
      }
    }
   

    if(this.isEditForm && this.formConfigData?.length){
      this.formConfigData=this.formConfigData.map(data=>{
      if((data.field==='maxThreshold' || data.field==='minThreshold') && data.type==='number' && !data.fieldValue){
        return {...data,fieldValue:'0'}
      }
        return data
      })
    }
  }


  // For Rearranging the Form Fields , So Accordian will come at end
  rearrangeAccordionsInPlace(data) {
    let nonAccordionIndex = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i].type !== 'accordian') {
            const temp = data[i];
            data[i] = data[nonAccordionIndex];
            data[nonAccordionIndex] = temp;
            nonAccordionIndex++;
        }
    }
  } 


  pushInputValues(value, isDisabled) {
    let _this = this;
    let innerArray: any = [];
    let outerArray: any = [];
    if (value.required) {
      innerArray.push(Validators.required);
    }
    if (value.fieldValue && value.default) {
      _this.defaultFields.push(value.field);
      outerArray.push({ value: value.default, disabled: true });
    } else if (typeof value.fieldValue==='boolean' || value.fieldValue) {
      outerArray.push({ value: value.fieldValue, disabled: isDisabled });
    } else {
      outerArray.push({ value: null, disabled: isDisabled });
    }
    outerArray.push(innerArray);
    return outerArray;
  }

  pushDateInputValues(value,isDisabled) {
    let _this = this;
    let innerArray: any = [];
    let outerArray: any = [];
    if (value.required) {
      innerArray.push(Validators.required);
    }
    if (value.fieldValue && value.default) {
      _this.defaultFields.push(value.field);
      outerArray.push({
        value: new Date(value.default),
        disabled: true,
      });
    } else if (value.fieldValue) {
      outerArray.push({
        value: new Date(value.fieldValue),
        disabled: isDisabled,
      });
    } else {
      outerArray.push({ value: null, disabled: isDisabled });
    }
    outerArray.push(innerArray);
    return outerArray;
  }

  createEditForm(formConfigData, isDisabled) {
    let _this = this;
    let formObject = {};
    this.defaultFields = [];
    this.hiddenFields = [];
    this.arrayFields = [];
    forEach(formConfigData, (value) => {
      if (value?.regexValidation) {
        this.regexValidation(value?.fieldValue, value?.regexValidation, value?.field);
      }
      switch (value.type) {
        case "text" ||
          "textarea" ||
          "number" ||
          "email" ||
          "colorPicker" ||
          "dropDown" ||
          "multiSelect" ||
          "preFill": {
          formObject[value.field] = _this.pushInputValues(value, isDisabled);
          break;
        }
        case "date": {
          formObject[value.field] = _this.pushDateInputValues(
            value,
            isDisabled
          );
          break;
        }
        case "hidden": {
          _this.hiddenFields.push(value);
          break;
        }
        case "array": {
          _this.arrayFields.push(value);
          formObject[value.field] = _this.pushInputValues(value, isDisabled);
          break;
        }
        case "accordian":{
          let accordionGroup = {};
          value.accordianData.forEach((accordianItem) => {
            let childGroup = {};
            if(accordianItem?.type=='accordian'){
              accordianItem.childData.forEach((childItem) => {
                childGroup[childItem.field] = value?.fieldValue?.[accordianItem?.field][childItem?.field] || null
              });
              accordionGroup[accordianItem.field] = _this.fb.group(childGroup);
            }else if(accordianItem?.type=='text' || accordianItem?.type=='password'){
              accordionGroup[accordianItem.field] = value?.fieldValue?.[accordianItem?.field] || null
            }else if(accordianItem?.type=='boolean'){
              accordionGroup[accordianItem.field] =  value?.fieldValue?.[accordianItem?.field] || false
            }else if(accordianItem?.type=='dropDown'){
              accordionGroup[accordianItem.field] =  value?.fieldValue?.[accordianItem?.field] || null
            }
          });
          formObject[value.field] = _this.fb.group(accordionGroup);
          break;
        }
        default: {
          formObject[value.field] = _this.pushInputValues(value, isDisabled);
          break;
        }
      }
    });
    this.form = this.fb.group(formObject);
    this.cloneForFormValuesToedit = {...this.form.value}
    this.isFormReadyToDisplay = true;
    this.showCircularLoader = false;
  }

  createAddForm(formConfigData) {
    let formObject = {};
    this.hiddenFields = [];
    this.arrayFields = [];
    let _this = this;
    forEach(formConfigData, function (value) {
      let innerArray: any = [];
      let outerArray: any = [];
      if (value.required) {
        innerArray.push(Validators.required);
      }
      if (value.defaultValue) {
        if (value.type === "multiSelect") {
          outerArray.push({
            value: [value.defaultValue.value],
            disabled: false,
          });
        } else {
          outerArray.push({ value: value.defaultValue.value, disabled: false });
        }
      } else if (value.type === "preFill") {
        if (value.value) {
          outerArray.push({ value: value.value, disabled: false });
        }
      }else if(value.type === 'accordian'){
        let accordionGroup = {};
        value.accordianData.forEach((accordianItem) => {
          let childGroup = {};
          if(accordianItem?.type=='accordian'){
            accordianItem.childData.forEach((childItem) => {
              childGroup[childItem.field] = null
            });
            accordionGroup[accordianItem.field] = _this.fb.group(childGroup);
          }else if(accordianItem?.type=='text' || accordianItem?.type=='password'){
            accordionGroup[accordianItem.field] =  null
          }else if(accordianItem?.type=='boolean'){
            accordionGroup[accordianItem.field] =  false
          }
          else if(accordianItem?.type=='dropDown'){
            accordionGroup[accordianItem.field] =  null
          }
        });
        formObject[value.field] = _this.fb.group(accordionGroup);
      }else {
        outerArray.push(null);
      }

      if (value.type === "hidden") {
        _this.hiddenFields.push(value);
      }
      if (value.type === "array") {
        _this.arrayFields.push(value);
      }
      outerArray.push(innerArray);
      if(value.type != 'accordian'){
        formObject[value.field] = outerArray;
      }
    });
    this.form = this.fb.group(formObject);
    this.isFormReadyToDisplay = true;
    this.showCircularLoader = false;
  }

  getAllDynamicData(formConfigdata) {
    return new Promise((resolve) => {
      const allPromises = [];
      const groupedByFieldname = groupBy(formConfigdata, "field");
      const allFieldNames = keys(groupedByFieldname);
      // eslint-disable-next-line prefer-const
      // let allFieldWithDynamicData = [];
      forEach(allFieldNames, (fieldName, index) => {
        let fieldObj = groupedByFieldname[fieldName][0];
        if (
          fieldObj["isDynamic"] === true && !fieldObj['apiDetails']["fieldsToBeAttachedToUrl"] &&
          ((this.isEditForm &&
            fieldObj["type"] === "preFill" &&
            !fieldObj["fieldValue"]) ||
            (this.isEditForm && fieldObj["type"] !== "preFill") ||
            !this.isEditForm)
        ) {
          let url = "";
          if (fieldObj["apiDetails"] && fieldObj["apiDetails"]["url"]) {
            url = fieldObj["apiDetails"]["url"];
          }
          if(fieldObj['apiDetails']["pathParams"]){
            let params = fieldObj["apiDetails"]["pathParams"]
            url += this.convertToQueryString(params,'path')
          }
          if(fieldObj['apiDetails']["queryParams"]){
            let params = fieldObj["apiDetails"]["queryParams"]
            url += "?" + this.convertToQueryString(params,'query')
          }
          if (url && url.length > 0) {
            const promise = this.getDynamicDataByApi(url).then((_response) => {
              fieldObj = {...fieldObj, response: {}};
              fieldObj = {...fieldObj, response: _response};
              return fieldObj;
            });
            allPromises.push(promise);
          }
        }
        if (allFieldNames.length - 1 === index) {
          if (allPromises.length > 0) {
            Promise.all(allPromises).then((data) => {
              const grpRespByFields = groupBy(data, "field");
              const resFields = keys(grpRespByFields);
              // eslint-disable-next-line prefer-const
              let dropDownResponseConfigured = {};
              let fillValue = {};
              forEach(resFields, (resField) => {
                const resFieldObj = grpRespByFields[resField][0];
                const dropDownDetails = resFieldObj["dropDownDataDetails"];
                const dataPath = resFieldObj["dataPath"];
                if (dropDownDetails) {
                  const dynamicResponses = resFieldObj["response"];
                  const labelPointer =
                    dropDownDetails["label"]; /* .split('.') */
                  const valuePointer =
                    dropDownDetails["value"]; /* .split('.') */
                  const queryPointer =
                    dropDownDetails["query"];
                  const dropDownValue = map(
                    dynamicResponses,
                    (dynamicResponse) => {
                      const label = this.resolvepathNavigation(
                        labelPointer,
                        dynamicResponse
                      );
                      const value = this.resolvepathNavigation(
                        valuePointer,
                        dynamicResponse
                      );
                      const query = this.resolvepathNavigation(
                        queryPointer,
                        dynamicResponse
                      );
                      return { label, value, query };
                    }
                  );
                  dropDownResponseConfigured[resField] = [];
                  dropDownResponseConfigured[resField] = dropDownValue;
                }
                if (dataPath) {
                  const dynamicResponses = resFieldObj["response"];
                  const fillValueData = { data: dynamicResponses };
                  const preFillData = this.getDataByPath(fillValueData, dataPath);
                  fillValue[resField] = preFillData;
                }
              });

              this.formConfigData = map(this.formConfigData, (formObject) => {
                const _fieldName = formObject["field"];
                if (includes(resFields, _fieldName)) {
                  if (formObject["dropDownDataDetails"]) {
                    formObject = {...formObject, dropDownValues: dropDownResponseConfigured[_fieldName]};
                  }
                  if (formObject["type"] === "preFill") {
                    formObject = {...formObject, fieldValue: fillValue[_fieldName]};
                    formObject = {...formObject, value: fillValue[_fieldName]};
                  }
                }
                return formObject;
              });
              resolve(this.formConfigData);
            });
          } else {
            resolve(this.formConfigData);
          }
        }
      });
    });
  }

  convertToQueryString(params,type) {
    let queryString = '';
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
          if(params[key]=='accountId' && type=='query'){
            queryString += `&${key}=${this.selectedAccountId}&`
          }else{
            queryString += type=='path' ? `${key}/${params[key]}/` : `&${key}=${params[key]}&`;
          }
        }
    }
    queryString = queryString.slice(0, -1);
    return queryString;
  }
  getDataByPath(o: any, s: any) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
  getDynamicDataByApi(url: string) {
    return new Promise((resolve) => {
      this.eventManagementService
        .genericGetApiCall(url)
        .subscribe((_response: any) => {
          resolve(_response);
        });
    });
  }

  getDynamicSearchDataByApi(url: string) {
    return new Promise((resolve) => {
      this.eventManagementService
        .genericGetApiCall(url)
        .subscribe((_response: any) => {
          resolve(_response);
        });
    });
  }

  resolvepathNavigation(path: string, obj: any) {
    return path?.split(".")?.reduce(function (prev, curr) {
      return prev ? prev[curr] : null;
    }, obj || self);
  }

  disabledAndEnabledFields(formConfigData, isDisabled: boolean) {
    forEach(formConfigData, (value) => {
      if (value.type && value.type !== "hidden") {
        if (isDisabled) {
          this.form?.get(value.field)?.disable();
        } else {
          this.form?.get(value.field)?.enable();
        }
      }
    });
  }

  // deregisterSelectedAsset() {
  //   this.deregisterAsset.emit(this.eventToEdit._id);
  // }

  deregisterSelectedAsset(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message:
        this.lang.getTranslation(
          "app.core.alert-popup.de-register-asset-selected"
        ) + " ?",
      icon: "pi pi-exclamation-triangle",
      key: "eventFormPopup",
      accept: () => {
        //confirm action
        this.deregisterAsset.emit(this.eventToEdit._id);
        this.splitScreenService.hideModalAndRemoveSplitScreen();
      },
      reject: () => {
        //reject action
      },
    });
    
  }

  checkSubmitBtnDisableStatus() {
    if (this.checkIfInternalFormConfigValid(this.countedObj)) {
      return true
    }
    if (this.checkIfInternalFormConfigValid(this.regexValidationObj)) {
      return true
    }
    if (!this.form.valid) {
      return true;
    } else if (this.form.valid) {
      if (this.isSubmitDisabled && !this.isEditForm) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkIfInternalFormConfigValid(obj) {
    for (let key in obj) {
      if (!obj[key]) {
        return true
      }
    }
    return false
  }

  getDropDownLabels(configArr: any) {
    const arr = map(configArr, (value) => {
      let dropdownValues = value?.dropDownValues;
      if(dropdownValues) {
        dropdownValues = map(dropdownValues, (field) => {
          const label = field?.label?.toString();
          field = { ...field, label: this.getLangLabel(label)};
          return field;
        });
        value = { ...value, dropDownValues: dropdownValues};
      }
      return value;
    });

    return arr;
  }

  getLangLabel(labelCode: string) {
    if (labelCode) {
      return this.lang.getTranslation(labelCode);
    }
  }

  checkIsCloneForm () {
    let isCloneForm = this.isCloneBtnReq && this.isEditForm
    if (this.isAssetForm && this.isEditForm) {
      isCloneForm = true;
    }
    this.createEditForm(this.formConfigData,isCloneForm)
  }
  onColorChanged(color,title): void {
    this.form.controls[title].setValue(color.target.value);
  }

  handleGenericDropDownChange(event: { value: string; }, field) {
    if (field?.isUnique) {
      this.handleIsUniqueAPi(event.value, field?.field)
    }
    if (field?.apiDetails?.searchAvailable) {
      this.handleSearchAvailableDropdownChange(event, field)
    } else if (field?.apiDetails?.triggerFields?.length > 0) {
      this.handleTriggerDropdownChange(event, field)
    }
  }

  handleSearchAvailableDropdownChange(event: { value: string; }, field, directCall?) {
    // this.resetValuesAfterDropDownChange()
    if (field?.isUnique && directCall) {
      this.handleIsUniqueAPi(event.value, field?.field)
    }
    if (!field?.apiDetails?.triggerFields?.length && !field?.apiDetails?.attachToUrl) {
      let apiDetails = field.apiDetails;
      let finalURL = apiDetails?.url + '&' + apiDetails?.serachQuery?.field + "=" + event.value;
      this.callingGenericAPIWithSettingResults(finalURL, field, event)
    } else if (field?.apiDetails?.attachToUrl) {
      let createdURL = field?.apiDetails?.url
      let fieldsToAttach = field?.apiDetails?.fieldsToBeAttachedToUrl;
      fieldsToAttach.forEach((replacement) => {
        const { key, value } = replacement;
        const values = value.split(".");

        const qryConfig = this.formConfigData.find(({ field }) => field === values[0]);

        const isQuery = qryConfig?.dropDownDataDetails?.query;
        const dropDownValues = qryConfig.dropDownValues;

        createdURL = createdURL.split(key).join(
          this.getQueryValue(
            this.form.value[values[0]],
            isQuery,
            dropDownValues
          )
        );
      });
      createdURL += event.value
      this.callingGenericAPIWithSettingResults(createdURL, field, event)
    }

  }

  getQueryValue(id, isQuery, dropDownValues) {
    if (isQuery) {
      return dropDownValues.find(({ value }) => id === value)?.query || id
    }
    return id
  }


  handleTriggerDropdownChange(_, field, isEdit?) {
    if (field?.apiDetails?.triggerFields?.[0]) {
      this.formConfigData?.forEach((config, index) => {
        // Currently we are taking only First Element from trigger Fields Array ... TO-DO
        if (config?.field === field?.apiDetails?.triggerFields[0]) {
          let createdURL = config?.apiDetails?.url
          if (config?.apiDetails?.attachToUrl) {
            let fieldsToAttach = config?.apiDetails?.fieldsToBeAttachedToUrl;
            fieldsToAttach.forEach((replacement) => {
              const { key } = replacement;
              if (isEdit) {
                let findIDX = this.formConfigData?.findIndex((el) => el?.field == field?.field)
                createdURL = createdURL.split(key).join(this.getQueryValue(this.formConfigData[findIDX]?.['fieldValue'], field.dropDownDataDetails.query, field.dropDownValues))
              } else {
                createdURL = createdURL.split(key).join(this.getQueryValue(this.form.value[field?.field], field.dropDownDataDetails.query, field.dropDownValues))
              }
            });
            this.eventManagementService.searchValueInAssetConifg(createdURL).subscribe((el: any[]) => {
              let result: { [key: string]: string }[] = [];
              el?.forEach((ele) => {
                let main: { [key: string]: string } = {};
                const labelKey = this.formConfigData[index]['dropDownDataDetails']['label'];
                const valueKey = this.formConfigData[index]['dropDownDataDetails']['value'];
                const queryKey = this.formConfigData[index]['dropDownDataDetails']['query'];

                main['label'] = ele?.[labelKey];
                main['value'] = ele?.[valueKey];
                main['query'] = ele?.[queryKey];


                result.push(main);
              });
              this.formConfigData[index]['dropDownValues'] = result;

              // Checking if Value exist in dropdown or not. If not present in dropdown , we will make a API call and add that value to dropdowm
              if(isEdit){
                const val = this.formConfigData[index].field;
                createdURL +=  this.form.value[val]
                if(!this.formConfigData[index]['dropDownValues'].some((el)=> el?.value == this.form.value[val])){
                  this.eventManagementService.searchValueInAssetConifg(createdURL).subscribe((res: any[]) => {
                    res?.forEach((ele) => {
                      let main: { [key: string]: string } = {};
                      const labelKey = this.formConfigData[index]['dropDownDataDetails']['label'];
                      const valueKey = this.formConfigData[index]['dropDownDataDetails']['value'];
                      const queryKey = this.formConfigData[index]['dropDownDataDetails']['query'];
                      main['label'] = ele?.[labelKey];
                      main['value'] = ele?.[valueKey];
                      main['query'] = ele?.[queryKey];
                      this.formConfigData[index]['dropDownValues'] = [...this.formConfigData[index]['dropDownValues'],main];
                    });
                  })
                }
              }
              

              if (this.form.contains(config.field)) {
                let control = this.form.get(config.field);
                if (control) {
                  control.setValue(result[0]?.value);
                  this.handleSearchAvailableDropdownChange({
                    value:
                      result[0]?.value
                  }, config, true)
                }
              }
            });
          }
        }
      })
    }
  }


  handleDataFilterInDropDown(event, field) {
    if (!field?.apiDetails?.serachQuery?.makeAPICall) return;

    let createdURL = field?.apiDetails?.url
    let fieldsToAttach = field?.apiDetails?.fieldsToBeAttachedToUrl;
    fieldsToAttach.forEach((replacement) => {
      const { key, value } = replacement;
      const values = value.split(".");

      const qryConfig = this.formConfigData.find(({ field }) => field === values[0]);

      const isQuery = qryConfig?.dropDownDataDetails?.query;
      const dropDownValues = qryConfig.dropDownValues;

      createdURL = createdURL.split(key).join(
        this.getQueryValue(
          this.form.value[values[0]],
          isQuery,
          dropDownValues
        )
      );
    });
    // createdURL += '&' + field?.apiDetails?.serachQuery?.field + "="
    if (!this.debouncedHandleInputChange) {
      this.debouncedHandleInputChange = this.hanldeInputDebounce((event: string) => {
        this.findFilterValuesAndSetValues(createdURL + event, field)
        this.debouncedHandleInputChange = null;
      }, 700);
    }
    this.debouncedHandleInputChange(event?.filter);
  }

  findFilterValuesAndSetValues(createdURL: string, field) {
    this.eventManagementService.searchValueInAssetConifg(createdURL).subscribe((res: any[]) => {
      let result = [];
      let findIDX = this.formConfigData?.findIndex((el) => el?.field == field?.field)
      res?.forEach((ele) => {
        let main: { [key: string]: string } = {};
        const labelKey = this.formConfigData[findIDX]['dropDownDataDetails']['label'];
        const valueKey = this.formConfigData[findIDX]['dropDownDataDetails']['value'];
        const queryKey = this.formConfigData[findIDX]['dropDownDataDetails']['query'];

        main['label'] = ele?.[labelKey];
        main['value'] = ele?.[valueKey];
        main['query'] = ele?.[queryKey];


        result.push(main);
      });
      this.formConfigData[findIDX]['dropDownValues'] = [...result];

    })
  }

  findFirstValueForEditFormOnly(){

  }

  getNestedValue(object: any, path: string): any {
    return path?.split('.').reduce((acc, key) => (acc && acc[key] !== undefined) ? acc[key] : null, object);
  }

  isFieldInvalid(requiredFields: string[]): boolean {
    return requiredFields?.some(field => !this.parentStateForPrefillValues[field]);
  }


  callingGenericAPIWithSettingResults(url: string, field, event) {
    this.eventManagementService.searchValueInAssetConifg(url).subscribe((res: any[]) => {
      let matchedItem = res.find(item => item.name === event.value);
      this.parentStateForPrefillValues[field?.field] = matchedItem
      this.formConfigData?.forEach((config) => {
        if (config?.isPrefill) {
          let valueToSet = this.getNestedValue(this.parentStateForPrefillValues, config.prefillValuePath);
          if (this.form.contains(config?.field)) {
            let control = this.form.get(config?.field);
            if (control) {
              control.setValue(valueToSet);
            }
          }
        }
      });
    });
  }


  resetValuesAfterDropDownChange() {
    this.parentStateForPrefillValues = {}
    this.formConfigData?.forEach((config) => {
      if (config?.isPrefill) {
        if (this.form.contains(config?.field)) {
          let control = this.form.get(config?.field);
          if (control) {
            control.setValue(undefined);
          }
        }
      }
    });
  }

  addingTheValuesForConfiguration() {
    this.formConfigData?.map((value) => {
      if (value?.apiDetails?.triggerFields) {
        // Currently we are taking only First Element from trigger Fields Array ... TO-DO
        this.handleTriggerDropdownChange('', value, true)
      }
    })
  }

}

export interface ICountedObjDisableChecker{
  [key:string]:boolean
}

export interface IValueExistChecker{
  [key:string]:IisUniqueValues
}
interface IisUniqueValues{
  isUnique:boolean,
  isValueExistMessage: string,
  assetIndex?: number
}
