@if (isShowAsset) {
  <div>
    <app-loader></app-loader>
  </div>
}
<div class="container-fluid p-0">
  <div class="grid assetContainer">
    <!-- <div class="col-1 assets-menu" *ngIf="!hideAssetsMenu">
    <div class="grid" style="height: 100%">
      <div class="asset-menu-arrows top-arrow-container" *ngIf="assetCountCardArray.length > 0 && menuOverFlowFlag || sliderVariables.slideMargin < 0" (click)="glider('up')">
        <img src="../../assets/up-arrow.svg" alt="">
      </div>
      <ul class="assets-list-div" style="width: 100%; overflow: hidden"[ngStyle]="{'margin' : menuOverFlowFlag || sliderVariables.slideMargin < 0 ? '70px 0px' : '20px 0px', 'max-height': menuOverFlowFlag || sliderVariables.slideMargin < 0 ? 'calc(100% - 80px)' : 'calc(100% - 10px)' }">
        <div class="slide-track">
          <li class="assets-menu-item" *ngFor="let dash of assetCountCardArray" (click)="addActiveClass(dash)" [ngClass]="dash['embossedFlagStatus'] || dash && dash['isActiveItem'] ? 'active' : ''">
            <a class="icon-container" [routerLink]="'/asset/' + dash['type']" routerLinkActive>
              <app-count-card [dashData]="dash" [showIcons]="true" [selectedAssetTypesForFilter]="selectedAssets"
              (embossedFlagStatus)="setEmbossedFlagStatus($event)"></app-count-card>
            </a>
          </li>
        </div>
      </ul>
      <div class="asset-menu-arrows bottom-arrow-container" *ngIf="assetCountCardArray.length > 0 && menuOverFlowFlag || sliderVariables.slideMargin < 0" (click)="glider('down')">
        <img src="../../assets/down-arrow.svg" alt="">
      </div>
    </div>

  </div> -->

    <!-- <div class="col-1 assets-hover-menu">
    <div class="grid" style="height: 100%">
      <div class="asset-menu-arrows top-arrow-container" *ngIf="assetCountCardArray.length > 0 && menuOverFlowFlag|| sliderVariables.slideMargin < 0" (click)="glider('up')">
      </div>
      <ul class="assets-list-div" style="width: 100%" style="width: 100%; overflow: hidden"[ngStyle]="{'margin' : menuOverFlowFlag ? '70px 0px' : '20px 0px', 'max-height': menuOverFlowFlag ? 'calc(100% - 80px)' : 'calc(100% - 10px)' }">
        <div class="slide-track">
          <li *ngFor="let dash of assetCountCardArray" [ngClass]="dash['embossedFlagStatus'] || dash && dash['isActiveItem'] ? 'active' : ''">
            <div class="count-container">
              <app-count-card [dashData]="dash" [showCounts]="true" [selectedAssetTypesForFilter]="selectedAssets"
              (embossedFlagStatus)="setEmbossedFlagStatus($event)"></app-count-card>
            </div>
          </li>
        </div>
      </ul>
      <div class="asset-menu-arrows bottom-arrow-container" *ngIf="assetCountCardArray.length > 0 && menuOverFlowFlag || sliderVariables.slideMargin < 0" (click)="glider('down')">
      </div>
    </div>
  </div> -->

    <div class="content-wrapper" [ngClass]="hideAssetsMenu ? 'col-12 assetMenuHidden' : 'col-12'">
      <div class="col-12 header">
        <div class="grid mt-3 mx-0 px-2 header-inner-grid mx-2">
          @if (selectedAssetsNames.length > 0 && ACTIONS.WRITE | able: SUBJECT.ASSET) {
            <div class="header-buttons-container">
              <div class="grid mx-0 header-buttons-container-inner-grid">
                <div class="MR10">
                  <div style="position: relative">
                    <p-button class="filter-btn" (click)="opFilter.toggle($event)"
                              styleClass="ml-3"
                              [pTooltip]="filterFields.isFilterApplied ? getLangLabel('app.user.filter-applied') : getLangLabel('app.user.filter-list')"
                              tooltipPosition="left"
                              icon="pi pi-filter">
                    </p-button>
                    <span [ngClass]="{'filter-applied' : filterFields.isFilterApplied}"></span>
                  </div>
                  <p-overlayPanel
                    [dismissable]="true"
                    styleClass="filter-panel-container"
                    showCloseIcon="true"
                    (onHide)="filterBtnClicked()"
                    (onShow)="filterBtnClicked()"
                    #opFilter>
                <div class="filter-container">
                  <div class="input-outer-grid PR">
                    <p-multiSelect [options]="groupsList" [(ngModel)]="filterFields.selectedGroups" optionLabel="label"
                                   (ngModelChange)="getSelectedGroups($event)" optionValue="_id" placeholder="Groups">
                      <ng-template let-group pTemplate="item">
                        <div class="flex align-items-center gap-2">
                          <div>{{ group.label }}</div>
                          <span class="ml-1" style="width:15px;height:15px;display: inline-block"
                                [ngStyle]="{'background-color': group ? group['color'] : ''}"></span>
                        </div>
                      </ng-template>
                    </p-multiSelect>
                  </div>
                </div>
                  </p-overlayPanel>
                </div>

                @if(assetOperations().create){
                <p-splitButton 
                [label]="selectedAssetsNames | translate" 
                icon="pi pi-plus"
                (onClick)="addNewAsset(selectedAssetsNames)" 
                [model]="assetCreationButtons" />
              }

                <!-- <p-button label="{{selectedAssetsNames | translate}}" icon="pi pi-plus" class="cursorPointer"
                          data-keyboard="false" (onClick)="addNewAsset(selectedAssetsNames)">
                </p-button>
                <p-button label="Multiple {{selectedAssetsNames | translate}}" icon="pi pi-plus" class="cursorPointer"
                data-keyboard="false" (onClick)="addMultipleAssets(selectedAssetsNames)"></p-button> -->
              </div>
            </div>
          }
        </div>
      </div>

      <div class="grid mx-0 mt-2">
        @for (asset of allAssets; track asset; let i = $index) {
          <div class="asset-card-deck relative"
               [ngClass]="(splitScreenActive$ | async) ? 'xl:col-6' :'col-12 zero-padding md:col-12 xl:col-3'">
            <app-thing-card [splitScreenIsActive]="(splitScreenActive$ | async)" [assetData]="asset"
                            [assetConfigDetails]="assetConfigDetails" [showCloseBtn]="false"
                            [showGroupBtn]="true" [upadtedCartSpecificDetails]="upadtedCartSpecificDetails"
                            [isCardClickable]="assetOperations()?.read" [showStatusOnAssetCard]="asset.showStatus"
                            [assetDetailsAfterLinkingUpdated]="assetDetailsAfterLinkingUpdated"
                            (editAsset)="editAsset($event)" [config]="assetConfigDetails" [selectedId]="assetId"
                            [isThumbnailAccepted]="isThumbnailAccepted"
                            [isprofilePhotoAccepted]="isprofilePhotoAccepted"
                            [imageDisplayStatus]="(cardsImageDisplayStatus$ | async)?.[asset.assetType]"></app-thing-card>
          </div>
        }
        <div
          class="W100FL TAC">@if (allAssets.length === 0 && (selectedAssetsNames != '') && !isShowAsset && filterFields.selectedGroups.length < 1) {
          <h3 class="asset-not-found">
            <span>&ldquo;</span>{{ 'sys.asset-not-found-selected-type' | translate }} {{ selectedAssetsNames | translate }}
            <span>&rdquo;</span></h3>
        }</div>
        <div
          class="W100FL TAC">@if (allAssets.length === 0 && (selectedAssetsNames == '') && !isShowAsset && filterFields.selectedGroups.length < 1) {
          <h3 class="asset-not-found"><span>&ldquo;</span>{{ 'sys.asset-not-found' | translate }}<span>&rdquo;</span>
          </h3>
        }</div>
        <div class="W100FL TAC">@if (allAssets.length === 0 && filterFields.selectedGroups.length > 0 && !isShowAsset) {
          <h3 class="asset-not-found"><span>&ldquo;</span>{{ 'sys.asset-not-found-selected-groups' | translate }} <span>&rdquo;</span>
          </h3>
        }</div>
      </div>
    </div>
    @if (allAssets.length > 0) {
      <div class="col-12 flex justify-content-center" style="margin-top: 10px">
        @if (pagination.totalNoOfRecords > 0) {
          <p-paginator class="pPaginator" [rows]="pagination.recordsPerPage"
        [totalRecords]="pagination.totalNoOfRecords"
                       (onPageChange)="getDataByPageNo($event)"
        [showJumpToPageDropdown]="true" [showPageLinks]="false"
        currentPageReportTemplate="{first} to {last} of {totalRecords}">
      </p-paginator>
        }
      </div>
    }
  </div>



  <div>
    <app-right-sidebar (addGroupDetailsToMainComponent)="addGroupsToAsset($event)" (delinkAssetDetailsToMainComponent)="delinkAsset($event)"
                       (delinkServiceDetailsToMainComponent)="delinkService($event)" (deregisterAssetToMainComponent)="deregisterAsset($event)"
                       (formSubmitTrigger)="onNotificationEventSubmit($event)"
                       (imageUploadedFlag)="setProfilePhotoUpdatedFlag($event)" (linkAssetDetailsToMainComponent)="linkAsset($event)"
                       (linkServiceDetailsToMainComponent)="linkServicesToAsset($event)" (removeGroupDetailsToMainComponent)="removeGroupsFromAssets($event)"
                       (resetOnModelClosed)="resetOnModelClosed()" (syncUpdateAssetCartEvent)="syncUpdateAssetCart($event)"
                       (toggleActiveClassEvent)="toggleActiveClassEvent()"
                       [addGroupAction]="_addGroups.action"
                       [allServersStats]="allServersStatsArray" [allServicesStatus]="allServicesStatus"
                       [assetConfigDetails]="assetConfigDetails" [assetData]="assetData"
                       [assetLinkedDetails]="assetLinkedDetails"
                       [assetTypeToSend]="assetTypeToSend"
                       [carLive]="carLive"
                       [eventToEdit]="eventToEdit"
                       [isAssetDeregisteredSuccess]="isAssetDeregisteredSuccess"
                       [isEventSelectedToEdit]="true"
                       [isLinkDelinkAction]="linkDelinkAction"
                       [isQrCodeRequired]="isQrCodeRequired"
                       [isServiceLinkDelinkAction]="isServiceLinkDelinkAction"
                       [isThumbnailAccepted]="isThumbnailAccepted"
                       [isprofilePhotoAccepted]="isprofilePhotoAccepted"
                       [modalTitle]="assetLabel"
                       [isAssetForm]="true"
                       [removeGroupAction]="_removeGroups.action"
                       [resetFormData]="resetFormData"
                       [resetFormData]="resetFormData"
                       [serviceAssetConfigDetails]="serviceAssetConfigDetails"
                       [showCircularLoaderValue]="showCircularLoaderValue"
                       [showDeRegisterBtn]="showDeRegisterBtn && assetOperations()?.delete"
                       [showUpdateBtn]="showUpdateBtn && assetOperations()?.update"
                       [showEditIconForNonServiceType]="assetOperations()?.update"

    ></app-right-sidebar>
  </div>


  <!--<app-right-sider-to-add [resetAddAssetFormValue]="resetAddAssetFormValue" [selectedAssetConfigDetails]="selectedAssetConfigDetails"
  (savedAssetCardToAssetList)="savedAssetCardToAssetList($event)" (assetRegistrationErrorMsgToAssetList)="assetRegistrationErrorMsgToAssetList($event)"
  (createModalClosedEvent)="createModalClosedEvent($event)"></app-right-sider-to-add>-->

  @if(assetOperations().create){
  <app-notification-event-create-form
    [formData]="formData"
    [formConfiguration]="selectedAssetConfigDetails"
    [resetForm]="resetAddAssetFormValue"
    [isEventSelectedToEdit]="isEventSelectedToEdit"
    [eventToEdit]="eventToEdit"
    (formSubmitTrigger)="onNotificationEventSubmit($event)"
    (resetOnModelClosed)="resetOnModelClosed()"
    [assetTypeToSend]="assetTypeToSend"
    (createModalClosedEvent)="createModalClosedEvent()">
  </app-notification-event-create-form>

  @if(showMultipleAssetCreationForm()){
  <app-multiple-assets-creation (emmitAssetsData)="receiveMultipleAssetsData($event)" (createModalClosedEvent)="showMultipleAssetCreationForm.set(false)" [showForm]="showMultipleAssetCreationForm()"
  [multipleAssetsCount]="3" [formConfiguration]="selectedAssetConfigDetailsForMultiplAssets()"
  [resetForm]="resetAddMultipleAssetFormValue()">
</app-multiple-assets-creation>}
}

  <app-alert-popup [selectedAssetToLinkCount]="selectedAssetToLinkCount" [errorMessages]="assetRegisterErrorMessages"
                   (confirmedAddingGroup)="confirmedAddingGroup($event)" (confirmedDelink)="confirmedDelink($event)"
                   (confirmedDeregisteringAsset)="confirmedDeregisteringAsset($event)"
                   (confirmedServiceDelink)="confirmedServiceDelinkFromAsset($event)"
                   (confirmedServicelink)="confirmedServicelinkToAsset($event)"
                   (confirmedlink)="confirmedlink($event)" [selectedGroupsToAddCount]="_addGroups.count"
                   [selectedGroupsToRemoveCount]="_removeGroups.count"
                   (confirmedRemovingGroup)="confirmedRemovingGroup($event)">
  </app-alert-popup>

</div>

<p-confirmPopup key="asset-list-popup"></p-confirmPopup>
<p-toast key="asset-list-toast"></p-toast>
